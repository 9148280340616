
.Login {
  width: 100%;
  height: 100vh;
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: '"#f3f3f3'; }

.Login > div {
  width: 100%;
  height: 100vh;
  background: '#fff';
  padding: 30px;
  box-sizing: border-box; }

.LoginContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around; }

.ContainerImage {
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 187px;
    height: 80px; } }

.FloatTitle {
  font-style: normal;
  font-weight: 500;
  margin-top: 60px;
  font-size: 30px;
  line-height: 34px;
  text-align: center;
  max-width: 270px;
  color: #4A4A49; }

.Separator {
  background: '#f3f3f3'; }

.ContainerButton {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  color: '#f3f3f3';
  h2 {
    display: none;
    margin-bottom: 20px; } }

.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 320px;
  min-width: 260px;
  width: 100%;
  height: 50px;
  margin: auto;
  background: '#fff';
  border: none;
  border-radius: 30px;
  box-shadow: 1px 1px 5px #000;
  color: '#000';
  font-family: sans-serif;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.5s ease;
  svg {
    margin-left: 20px; }
  &:hover {
    background: ' #f5f5f5'; } }

@media screen and ( min-width: 900px ) {
  .Login > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0; }
  .LoginContainer {
    width: 865px;
    height: auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: '#fff';
    box-shadow: 0px 6px 6px '#000';
    border-radius: 10px; }
  .ContainerImage {
    width: 49%;
    padding: 0; }
  .Separator {
    width: 3px;
    height: 80%; }
  .ContainerButton {
    width: 49%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2 {
      display: inline-block; } }
  .Button {
    border-radius: 6px; }
  .FloatTitle {
    padding: 15px 30px;
    margin-top: 0;
    font-size: 18px;
    max-width: 100%;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    background: '#fff'; } }

.Login {
  width: 100%;
  height: 100vh;
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: '#f3f3f3'; }

.Container {
  display: flex;
  background: '#fff'; }

.LoginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  padding: 20px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  color: '#000'; }

.Header {
  margin-bottom: 20px; }

.Button {
  color: white;
  background-color: '#fff';
  transition: 0.5s ease;
  width: 100%;
  margin-bottom: 5px;
  padding: 10px;
  &:hover {
    background-color: '#f5f5'; } }

.LinkBottomContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 15px 0px 0px 50px; }

.CreateAccount {
  color: '#000';
  font-weight: bold; }

.TextError {
  padding-left: 3px;
  font-size: 14px;
  color: 'red'; }

@media screen and ( min-width: 600px ) {

  .Container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent; }

  .LoginForm {
    border-radius: 5px;
    padding: 40px;
    margin: 0px; }

  .LinkBottomContainer {
    text-align: start; } }
