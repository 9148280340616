.date-label {
  font-weight: bold;
  font-size: 18px;
}
.table-label {
  font-weight: bold;
  font-size: 40px;
  letter-spacing: 5px;
  margin: 20px;
}
.title {
  margin: 20px 0px;
  font-size: 35px;
  letter-spacing: 10px;
}
.text-empty {
  font-weight: bold;
  color: rgb(214, 212, 212);
  font-size: 30px;
  margin: 80px;
}
.loading3 {
  margin-top: 80px !important;
  p {
    font-size: 25px;
  }
}
.div-table-pedidos {
  border-radius: 10px;
  padding: 20px;
  background: white;
  box-shadow: -1px 1px 5px -1px rgba(0, 0, 0, 0.57);
}
.producto-nombre-label {
  font-size: 18px !important;
  span {
    color: #337ab7;
  }
}
.producto-option {
  color: gray;
  font-weight: 300 !important;
}
.tabs-cuadratura {
  .nav-link {
    color: #30414f !important;
    &.active {
      color: white !important;
      background-color: #30414f !important;
    }
  }
}
.text-bold {
  font-weight: bold;
}

.cuadraturaTitle {
  font-size: 1.2rem;
}

.cuadraturaTitleName {
  font-size: 1rem;
}

.subtitle {
  font-size: 25px;
  margin-top: 40px;
  margin-bottom: 20px;
}
