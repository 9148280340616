.dropdown {
  & .dropdown-toggle {
    &.no-caret::after {
      display: none;
    }
  }
}
.dropdown-transparent,
.dropdown-transparent:focus{
  background-color: #0e202f;
  color: white;
  border: none;
  box-shadow: none;
  cursor: pointer;
  option{
    background-color: white;
    color: black;
  }
}
